<template>
  <div class="risk-decrement">
    <div class="risk-decrement-project">
      <div class="flex-box flex-items" style="flex: 1">
        <el-tabs v-model="activeName" @tab-click="handleTabClick" style="width: 100%;">
          <el-tab-pane label="部门数据看板" name="department">
            <div
              class="el-form demo-form-inline el-form--inline"
              style="transform: translateY(9px)"
            >
              <div class="el-form-item el-form-item--small">
                <div class="el-form-item__label" style="width: 80px">
                  部门名称:
                </div>
                <div class="el-form-item__content">
                  <selectorStaff v-model="departmentForm.deptId" />
                </div>
              </div>
              <div class="el-form-item el-form-item--small">
                <div class="el-form-item__label" style="width: 80px">
                  时间:
                </div>
                <div class="el-form-item__content">
                  <el-date-picker 
                    v-model="departmentForm.year" style="width: 120px;"
                    type="year" value-format="yyyy"
                    placeholder="选择年">
                  </el-date-picker>
                  <el-select style="margin-left: 20px;width: 120px;" v-model="departmentForm.month" clearable placeholder="选择月">
                    <el-option
                      v-for="item in monthOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select style="margin-left: 20px;width: 120px;" v-model="departmentForm.week" clearable placeholder="选择周">
                    <el-option
                      v-for="item in Math.ceil(365 / 7)"
                      :key="item"
                      :label="'第' + item + '周'"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="项目数据看板" name="project">
            <div
              class="el-form demo-form-inline el-form--inline"
              style="transform: translateY(9px)"
            >
              <div class="el-form-item el-form-item--small">
                <div class="el-form-item__label" style="width: 80px">
                  项目名称:
                </div>
                <div class="el-form-item__content">
                  <el-select
                    v-model="projectForm.projectId"
                    filterable
                    placeholder="请选择"
                    @change="handleProjectSelect"
                  >
                    <el-option
                      v-for="item in projectList"
                      :key="item.id"
                      :label="item.projectName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="el-form-item el-form-item--small">
                <div class="el-form-item__label" style="width: 80px">
                  时间:
                </div>
                <div class="el-form-item__content">
                  <el-date-picker 
                    v-model="projectForm.year" style="width: 120px;"
                    type="year" value-format="yyyy"
                    placeholder="选择年">
                  </el-date-picker>
                  <el-select style="margin-left: 20px;width: 120px;" v-model="projectForm.month" clearable placeholder="选择月">
                    <el-option
                      v-for="item in monthOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select style="margin-left: 20px;width: 120px;" v-model="projectForm.week" clearable placeholder="选择周">
                    <!-- <el-option
                      v-for="item in WeekOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option> -->
                    <el-option
                      v-for="item in Math.ceil(365 / 7)"
                      :key="item"
                      :label="'第' + item + '周'"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <!-- <el-button plain @click="handleReset">重置</el-button>
        <el-button type="primary" plain @click="handleAnew">查询</el-button> -->
      </div>
    </div>
    <div class="flex-box flex-items">
      <accident :options="boardObj.platformPolicySta" style="flex: 1" />
      <div style="width: 10px"></div>
      <barCharth :options="boardObj.employeePremiumList	" style="flex: 1" />
    </div>
    <div class="flex-box flex-items">
      <Income :options="boardObj.policyPremiumList" :lineXName="lineType" style="flex: 1" />
      <div style="width: 10px"></div>
      <attainment :options="boardObj.policyPremiumAchievedList" :lineXName="lineType" style="flex: 1" />
    </div>
    <div class="flex-box flex-items">
      <equipment :options="boardObj.deviceIngredientList" style="flex: 1" />
      <div style="width: 10px"></div>
      <policy :options="boardObj.premiumIngredientList" style="flex: 1" />
    </div>
  </div>
</template>

<script>
import {
  getRiskPage,
  saveDataConfig,
  getWorkSituation,
  getHourCount, projectDataBoard, deptDataBoard
} from "@/api/SystemData";
import { pageProjectList } from "@/api/project";
import selectorStaff from "@/views/SystemManagement/components/staff/selectorStaff.vue";
import accident from "@/views/routerPerformanceManagement/components/performancePanel/components/accident.vue";
import barCharth from "@/views/routerPerformanceManagement/components/performancePanel/components/barCharth.vue";
import attainment from "@/views/routerPerformanceManagement/components/performancePanel/components/attainment.vue";
import equipment from "@/views/routerPerformanceManagement/components/performancePanel/components/equipment.vue";
import Income from "@/views/routerPerformanceManagement/components/performancePanel/components/Income.vue";
import policy from "@/views/routerPerformanceManagement/components/performancePanel/components/policy.vue";
import dayjs from 'dayjs';
export default {
  name: "performancePanel",
  components: {
    accident,
    barCharth,
    equipment,
    attainment,
    Income,
    policy,
    selectorStaff,
  },
  data() {
    return {
      projectId: "",
      projectList: [],
      unitTime: {},
      activeName: "department",
      projectForm: {
        projectId: "",
        month: null,
        week: null,
        year: dayjs().format('YYYY')
      },
      departmentForm: {
        deptId: '',
        month: null,
        week: null,
        year: dayjs().format('YYYY')
      },
      monthOptions: [
        {
          label: "一月",
          value: 1,
        },
        {
          label: "二月",
          value: 2,
        },
        {
          label: "三月",
          value: 3,
        },
        {
          label: "四月",
          value: 4,
        },
        {
          label: "五月",
          value: 5,
        },
        {
          label: "六月",
          value: 6,
        },
        {
          label: "七月",
          value: 7,
        },
        {
          label: "八月",
          value: 8,
        },
        {
          label: "九月",
          value: 9,
        },
        {
          label: "十月",
          value: 10,
        },
        {
          label: "十一月",
          value: 11,
        },
        {
          label: "十二月",
          value: 12,
        },
      ],
      WeekOptions: [
        {
          label: "第一周",
          value: 1,
        },
        {
          label: "第二周",
          value: 2,
        },
        {
          label: "第三周",
          value: 3,
        },
        {
          label: "第四周",
          value: 4,
        },
        {
          label: "第五周",
          value: 5,
        },
      ],
      boardObj: {},
      lineType: '月份'
    };
  },
  watch: {
    projectForm: {
      handler(v) {
        this.requestData();
      },
      deep: true
    },
    departmentForm: {
      handler(v) {
        this.requestData();
      },
      deep: true
    }
  },
  mounted() {
    pageProjectList({
      pageIndex: 1,
      pageNum: 1,
      pageSize: 9999
    }).then((res) => {
      if (res.data.records.length > 0) {
        this.projectList = res.data.records;
        this.projectName = res.data.records[0].projectName;
        this.projectId = res.data.records[0].projectId;
        // this.getDetails();
        // this.getWorkDetail();
        // this.getWorkHour();
        // this.getConfig();
      }
    });
    this.requestData()
  },
  beforeDestroy() {
    //  clearInterval(this.timer); //销毁定时器
  },
  methods: {
    requestData() {
      console.log(this.activeName);
      if (this.activeName == "department") {
        if (this.departmentForm.week) {
          this.lineType = '日';
        }else if (this.departmentForm.month) {
          this.lineType = '日';
        }else {
          this.lineType = '月份';
        }
        deptDataBoard(this.departmentForm).then(res=>{
          this.boardObj = res.data;
        })
      }else {
        if (this.projectForm.week) {
          this.lineType = '日';
        }else if (this.projectForm.month) {
          this.lineType = '日';
        }else {
          this.lineType = '月份';
        }
        projectDataBoard(this.projectForm).then(res=>{
          this.boardObj = res.data;
        })
      }
    },
    init(isLoading) {
      // this.getDetails(isLoading);
      // this.getWorkDetail();
      // this.getWorkHour();
    },
    getDetails(isLoading) {
      getRiskPage(
        {
          projectId: this.projectId,
          runnerUnit: this.unitTime.runnerUnit,
          workUnit: this.unitTime.workUnit,
          alarmUnit: this.unitTime.alarmUnit,
          riskUnit: this.unitTime.riskUnit,
          ...this.interfaceData,
        },
        isLoading
      ).then((res) => {
        this.details = res.data;
      });
    },
    getWorkDetail() {
      getWorkSituation({
        projectId: this.projectId,
        runnerUnit: this.unitTime.runnerUnit,
        ...this.interfaceData,
      }).then((res) => {
        this.workSituation = res.data;
      });
    },
    getWorkHour() {
      getHourCount({
        projectId: this.projectId,
        runnerUnit: this.unitTime.workUnit,
        ...this.interfaceData,
      }).then((res) => {
        this.hourSituation = res.data;
      });
    },
    getNestedValue(obj, keys) {
      if (keys.length === 1) {
        return obj[keys[0]];
      }
      return this.getNestedValue(obj[keys[0]], keys.slice(1));
    },
    changeUnit(value, type) {
      this.unitTime[type] = value;
      if (type === "runnerUnit") {
        this.getWorkDetail();
      } else if (type === "workUnit") {
        this.getWorkHour();
      } else {
        this.getDetails();
      }
    },
    handleProjectSelect(data) {
      this.projectId = data;
      this.init(true);
    },
    handleCompanySelect(e) {
      console.log(e);
    },
    recover() {
      this.drawerShowList = {
        analysis: true,
        manHour: true,
        startBox: true,
        trendAnalyze: true,
        proportionAnalyze: true,
        accident: true,
        caseList: true,
        risk: true,
        driver: true,
      };
      this.drawerAnalysisList = [
        {
          title: "出险案件",
          icon: require("@/assets/images/riskDecrement/analysis-cxaj.png"),
          bgColor: "rgba(40, 144, 235, .1)",
          type: "1",
          showBox: true,
        },
        {
          title: "保险情况",
          icon: require("@/assets/images/riskDecrement/analysis-bxqk.png"),
          bgColor: "rgba(167, 123, 243, .1)",
          type: "2",
          showBox: true,
          itemList: [
            {
              text: "签单保费",
              key: ["signBill", "signBillAmount"],
              unit: "万元",
              bgColor: "rgba(167, 123, 243, 0.1)",
              textColor: "#a77bf3",
            },
            {
              text: "满期保费",
              key: ["signBill", "expireAmount"],
              unit: "万元",
              bgColor: "rgba(167, 123, 243, 0.1)",
              textColor: "#a77bf3",
            },
          ],
        },
        {
          title: "设备及机手情况",
          icon: require("@/assets/images/riskDecrement/analysis-sbjjsqk.png"),
          bgColor: "rgba(251, 194, 38, .1)",
          type: "2",
          showBox: true,
          itemList: [
            {
              text: "设备总数",
              key: ["deviceInfo", "deviceCount"],
              unit: "台",
              textColor: "#FBC226",
              bgColor: "rgba(251, 194, 38, .1)",
            },
            {
              text: "机手总数",
              key: ["jiShou", "jsCount"],
              unit: "人",
              textColor: "#FBC226",
              bgColor: "rgba(251, 194, 38, .1)",
            },
          ],
          particularList: [
            {
              text: "参训机手",
              key: ["jiShou", "jsCXCount"],
              unit: "人",
            },
            {
              text: "持证机手",
              key: ["jiShou", "jsCZCount"],
              unit: "人",
            },
          ],
        },
        {
          title: "风险管控",
          icon: require("@/assets/images/riskDecrement/analysis-fxgk.png"),
          bgColor: "rgba(83, 222, 138, .1)",
          type: "2",
          showBox: true,
          itemList: [
            {
              text: "风险管控天数",
              key: ["preventControl", "dayCount"],
              textColor: "#53DD89",
              bgColor: "rgba(83, 222, 138, .1)",
            },
            {
              text: "连续无事故天数",
              key: ["preventControl", "successionDayCount"],
              textColor: "#53DD89",
              bgColor: "rgba(83, 222, 138, .1)",
            },
          ],
          particularList: [
            {
              text: "风控培训",
              key: ["preventControl", "preventCount"],
              unit: "次",
            },
            {
              text: "培训总人数",
              key: ["preventControl", "trainCount"],
              unit: "人",
            },
            {
              text: "客服干预",
              key: ["preventControl", "interveneCount"],
              unit: "次",
            },
          ],
        },
      ];
    },
    changeShowList() {
      this.showList = JSON.parse(JSON.stringify(this.drawerShowList));
      this.analysisList = JSON.parse(JSON.stringify(this.drawerAnalysisList));
      this.isConfiguration = false;
      saveDataConfig({
        id: this.configId,
        contentJson: JSON.stringify({
          showList: this.showList,
          analysisList: this.analysisList,
        }),
      }).then((res) => {
        if (res.code === 200) return this.init();
      });
    },
    handleReset() {
      this.interfaceData = {};
      this.temporaryData = {};
      this.init(true);
    },
    handleAnew() {
      this.interfaceData = JSON.parse(JSON.stringify(this.temporaryData));
      this.init(true);
    },
    handleTabClick(e) {
      this.requestData();
    }
  },
};
</script>

<style lang="scss">
.risk-decrement-form-cascader {
  .el-scrollbar__wrap {
    max-height: 600px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table__header {
  .cell {
    font-weight: bold;
    color: #333;
  }
}
::v-deep .el-table__row {
  .cell {
    color: #333;
  }
}
.risk-decrement {
  &-project {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 4px;
    &-left {
      display: flex;
      align-items: center;
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.flex-items {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-12 {
  flex: 12;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
