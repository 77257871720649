<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>设备成分</span>
    </div>
    <div class="analysis-box-content flex-box">
      <Chart
        style="height: 300px; width: 100%"
        ref="chartBox"
        :option="barOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  watch: {
    options: {
      handler(v) {
        if (v && v.length) {
          this.initBar(v);
        }else {
          this.initBar([]);
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      barOptions: {},
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initBar();
    // });
  },
  methods: {
    initBar(data) {
      var piename = data.map(val=> val.deviceTypeName);
      var pievale = data.map(val=> val.deviceCount);
      var colors = ["#6699FF", "#66FFCC", "#FFCC33", "#66CCFF", "#9999FF"];
      var sum1 = 0;
      for (let j = 0; j < pievale.length; j++) {
        sum1 += pievale[j];
      }
      // console.log("sum1=" + sum1);
      // let title = "总额";
      let piedata = [];
      for (let i = 0; i < piename.length; i++) {
        piedata.push({
          name: piename[i],
          value: pievale[i],
          itemStyle: {
            // opacity: 0.7,
            color: colors[i],
          },
        });
      }
      let circularGraph = {
        head: false,
        tuglie: piename,
        data: piedata,
        sum: {
          name: ["总计"],
          number: sum1.toFixed(2),
        },
        color: ["#3aa0ff", "#36cbcb", "#4dcb73", "#975fe4"],
      };
      // let sum = 0;
      // circularGraph.data.map((item) => {
      //     sum += item.value;
      // })
      // circularGraph.sum.number = sum;
      this.barOptions = {
        tooltip: {
          trigger: "item",
          padding: [8,10,8,10],
          backgroundColor: '#fff',
          formatter: (ddd)=>{
            return `<div style="padding: 10px 8px;"><div style="display:flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #F5F5F5;width：122px;"><div style="color:#333;font-size:14px;font-weight: bold;">${ddd.percent}<span style="color:#333;font-size:12px">%</span></div><div style="color:#333;font-size:14px;font-weight: bold;margin-left:56px;">${ddd.value}<span style="color:#333;font-size:12px">台</span></div></div></div><div></div></div><div style="display:flex;align-items: center;"><div style="width: 14px;height: 14px;background: #33D0BD;border-radius:50%;"></div><div style="font-size: 12px;color: #333333;margin-left:2pxl">${ddd.name}</div></div></div>`
          },
        },
        legend: {
          //翻页按钮
          show: false
        },

        graphic: {
          type: "text",
          left: "34%",
          top: "50%",
          style: {
            textAlign: "center",
            fill: "#333",
            width: 20,
            height: 20,
            fontSize: 18,
            fontWeight: 100,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "80%"],
            center: ["50%", "50%"],
            startAngle: 50, //指引线的角度改变
            itemStyle: {
              normal: {
                label: {
                  formatter: '{as|{b}} {ds|{d}%} | {cs|{c}台}',
                  rich: {
                    as: {
                      color: '#333',
                      fontSize: 14,
                      fontWeight: 600
                    },
                    ds: {
                      color: '#333',
                      fontSize: 14,
                    },
                    cs: {
                      color: '#333',
                      fontSize: 14,
                    },
                  },
                },
                labelLine: {
                  show: true,
                  length: 15,
                  length2: 15,
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
                textColor: "#333",
              },
            },
            data: circularGraph.data, //数据
          },
        ],
        color: circularGraph.color, //颜
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  // padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 14px;
    border-bottom: 1px solid #f2f7fd;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    bottom: 15px;
    width: 4px;
    background: #4278c9;
  }
  &-content {
    padding: 0px 13px 14px;
    overflow: auto;
    .statistics-box {
      padding: 16px 24px;
      background: #eff7fd;
      border-radius: 10px;
      margin-bottom: 14px;
      &-title {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
      }
      &-num {
        display: flex;
        font-weight: bold;
        align-items: flex-end;
        font-size: 24px;
        color: #333333;
        margin-bottom: 8px;
        &-unit {
          font-size: 14px;
          color: #333333;
          margin-left: 8px;
        }
      }
      &-up {
        color: #f64a2d;
      }
      &-dowm {
        color: #00bc0d;
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
