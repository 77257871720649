<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>平台保单数据统计</span>
    </div>
    <div
      class="analysis-box-content flex-box"
    >
      <el-row :gutter="24" style="margin: 0 -7px;">
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" style="padding: 0 7px" v-for="item,index in statisticsList" :key="index">
          <div class="statistics-box">
            <div class="statistics-box-title">{{ item.name }}</div>
            <div class="statistics-box-num">
              {{ statisticsObj[item.key] || 0 }}
              <div class="statistics-box-num-unit">{{ item.unit }}</div>
            </div>
            <div class="statistics-box-title">
              <div style="margin-right: 8px">较上月</div>
              <div class="statistics-box-up" v-if="statisticsObj[item.contrastKey] && statisticsObj[item.contrastKey] >= 0">{{ (statisticsObj[item.contrastKey] || 0).toFixed(2) }}%</div>
              <div class="statistics-box-dowm" v-else>{{ (statisticsObj[item.contrastKey] || 0).toFixed(2) }}%</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    options: {
      handler(v) {
        if (v) {
          this.statisticsObj = Object.assign({}, v);
        }else {
          this.statisticsObj = {};
        }
      }
    }
  },
  data() {
    return {
      statisticsList: [
        {
          name: '部门总保费',
          key: 'deptSumPremium',
          reserveKey: 'deptSumPremium',
          unit: '万',
          contrastKey: 'deptSumPremiumPreCycle',
          reservecontrastKey: 'deptSumPremiumPreCycle',
        },
        {
          name: '新增客户数',
          key: 'newCustomerCount',
          unit: '',
          contrastKey: 'newCustomerCountPreCycle'
        },
        {
          name: '新增保单数',
          key: 'newPolicyCount',
          unit: '',
          contrastKey: 'newPolicyCountPreCycle'
        },
        {
          name: '理赔案件数',
          key: 'claimsCount',
          unit: '',
          contrastKey: 'claimsCountPreCycle'
        },
        {
          name: '满期赔付率',
          key: 'expireCompensate',
          unit: '%',
          contrastKey: 'expireCompensatePreCycle'
        },
        {
          name: '理赔赔付金额',
          key: 'claimsPremium',
          unit: '万',
          contrastKey: 'claimsPremiumPreCycle'
        }
      ],
      statisticsObj: {}
    };
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  // padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 16px 14px;
    border-bottom: 1px solid #F2F7FD;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    bottom: 15px;
    width: 4px;
    background: #4278c9;
  }
  &-content {
    padding: 20px 13px 0;
    overflow: auto;
    .statistics-box {
      padding: 16px 24px;
      background: #EFF7FD;
      border-radius: 10px;
      margin-bottom: 14px;
      &-title {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
      }
      &-num {
        display: flex;
        font-weight: bold;
        align-items: flex-end;
        font-size: 24px;
        color: #333333;
        margin-bottom: 8px;
        &-unit {
          font-size: 14px;
          color: #333333;
          margin-left: 8px;
        }
      }
      &-up {
        position: relative;
        color: #F64A2D;
      }
      &-up::before {
        content: '';
        position: absolute;
        right: -20px;
        top: -7;
        // width: 14px;
        border-top: 9px solid transparent;
        border-bottom: 9px solid #F64A2D;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
      &-dowm {
        position: relative;
        color: #00BC0D;
      }
      &-dowm::before {
        content: '';
        position: absolute;
        right: -20px;
        top: 4px;
        border-top: 9px solid #00BC0D;
        border-bottom: 9px solid transparent;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
