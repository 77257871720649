<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>员工保费统计</span>
    </div>
    <div class="analysis-box-content flex-box">
      <Chart
        style="height: 300px; width: 100%"
        ref="chartBox"
        :option="barOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  watch: {
    options: {
      handler(v) {
        if (v && v.length) {
          this.initBar(v);
        }else {
          this.initBar([]);
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      barOptions: {},
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initBar();
    // });
  },
  methods: {
    initBar(data) {
      this.barOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          show: false,
        },
            grid: {
              left: 68,
              right: 10,
              top: 40,
              bottom: 10
            },
        legend: {
          show: false,
        },
        xAxis: {
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#F2F7FD",
            },
          },
        },
        yAxis: {
          name: "单位：万元",
          nameTextStyle: {
            fontSize: 12,
            color: "#AAA",
          },
          type: "category",
          data: data.map(val=> val.userName),
          axisPointer: {
            type: "shadow",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              fontSize: 14,
              color: "#ccc",
              // color: '#F2F7FD'
            },
          },
          axisLabel: {
            show: true,
              fontSize: 14,
              color: '#333'
          }
        },
        series: [
          {
            name: "保费",
            type: "bar",
            color: '#4F83FD',
            data: data.map(val=> val.totalPremium),
            label: {
              show: true,
              color: '#fff',
              fontSize: 12,
              align: 'left'
            }
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  // padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 14px;
    border-bottom: 1px solid #f2f7fd;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    bottom: 15px;
    width: 4px;
    background: #4278c9;
  }
  &-content {
    padding: 0px 13px 14px;
    overflow: auto;
    .statistics-box {
      padding: 16px 24px;
      background: #eff7fd;
      border-radius: 10px;
      margin-bottom: 14px;
      &-title {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
      }
      &-num {
        display: flex;
        font-weight: bold;
        align-items: flex-end;
        font-size: 24px;
        color: #333333;
        margin-bottom: 8px;
        &-unit {
          font-size: 14px;
          color: #333333;
          margin-left: 8px;
        }
      }
      &-up {
        color: #f64a2d;
      }
      &-dowm {
        color: #00bc0d;
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
