<template>
  <div class="analysis-box">
    <div class="analysis-box-title flex-box flex-between flex-items">
      <span>保费目标达成率</span>
    </div>
    <div class="analysis-box-content flex-box">
      <Chart
        style="height: 300px; width: 100%"
        ref="chartBox"
        :option="barOptions"
      ></Chart>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Array,
      default: ()=> []
    },
    lineXName: {
      type: String,
      default: ''
    }
  },
  watch: {
    options: {
      handler(v) {
        if (v && v.length) {
          this.initBar(v);
        }else {
          this.initBar([]);
        }
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      barOptions: {},
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initBar();
    // });
  },
  methods: {
    initBar(data) {
      this.barOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#ddd",
            },
          },
          valueFormatter: (value) => value + '%',
          backgroundColor: "rgba(255,255,255,1)",
          padding: [5, 10],
          textStyle: {
            color: "#000",
          },
        },
        legend: {
          show: false
        },
        grid: {
          left: 30,
          right: 40,
          bottom: 2,
          top: 40,
          containLabel: true,
        },
        xAxis: {
          name: this.lineXName,
          nameTextStyle: {
            fontSize: 12,
            color: "#999",
          },
          type: "category",
          data: data.map(val=> val.crossName),
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#cfcfcf",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 12,
              color: "#333",
            },
          },
        },
        yAxis: 
          {
            type: "value",
            nameTextStyle: {
              fontSize: 12,
              color: "#999",
            },
            splitLine: {
              show: true,
              interval: "auto",
              lineStyle: {
                width: 1,
                color: '#F5F5F5',
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              formatter: '{value}%',
              textStyle: {
                fontSize: 12,
                color: "#333",
              },
            },
          },
        series: [
          {
            name: "达成率",
            type: "line",
            symbolSize: 6,
            symbol:'circle', 
            data: data.map(val=> val.achieved),
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: `rgba(255,153,0,0.1)`,
                    },
                    {
                      offset: 1,
                      color: "rgba(255,153,0,0)",
                    },
                  ],
                  false
                ),
              },
            },
            itemStyle: {
              borderWidth: 4,
              borderColor: '#FFB356',
              color: '#FFB356'
            },
            lineStyle: {
              normal: {
                width: 3,
                color: '#FFB356'
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-box {
  // padding: 16px 24px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
  &-title {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding: 14px;
    border-bottom: 1px solid #f2f7fd;
  }
  &-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 14px;
    bottom: 15px;
    width: 4px;
    background: #4278c9;
  }
  &-content {
    padding: 0px 13px 14px;
    overflow: auto;
    .statistics-box {
      padding: 16px 24px;
      background: #eff7fd;
      border-radius: 10px;
      margin-bottom: 14px;
      &-title {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: #666666;
        margin-bottom: 16px;
      }
      &-num {
        display: flex;
        font-weight: bold;
        align-items: flex-end;
        font-size: 24px;
        color: #333333;
        margin-bottom: 8px;
        &-unit {
          font-size: 14px;
          color: #333333;
          margin-left: 8px;
        }
      }
      &-up {
        color: #f64a2d;
      }
      &-dowm {
        color: #00bc0d;
      }
    }
  }
}
.flex-box {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.flex-items {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.margin-r {
  margin-right: 10px;
}
.margin-l {
  margin-left: 10px;
}
.color-b {
  color: #0080ff;
}
.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
</style>
